import { Box, Typography } from '@mui/material';
import CircularLoading from 'components/CircularLoading';
import { KeyedObject } from 'types';

interface LoadingPageProps extends KeyedObject {
  title?: string;
}

const LoadingPage = ({ title }: LoadingPageProps) => (
  <Box
    height="100vh"
    width="100vw"
    textAlign="center"
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <div>
      {title && <Typography gutterBottom>{title}</Typography>}
      <CircularLoading />
    </div>
  </Box>
);

export default LoadingPage;
