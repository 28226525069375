import { useEffect } from 'react';

import carrierAdministration from 'api/carrierAdministration';
import useApi from 'hooks/useApi';
import { useDispatch } from 'react-redux';
import {
  SET_BACKGROUND_COLOR,
  SET_CARRIER,
  SET_CONTRACT,
  SET_CURRENT_LOCATION,
  SET_PROFILE,
  SET_TESTING
} from 'store/actions';
import { ListResult, Profile, Carrier, Contract } from 'types/diingu';
import { useTheme } from '@mui/material';

const Initializer = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [profileResponse, profileRequest] = useApi<ListResult<Profile>>(
    carrierAdministration.getOwnProfile,
    null
  );

  const [carrierResponse, carrierRequest] = useApi<ListResult<Carrier>>(
    carrierAdministration.getCarrierData,
    null
  );

  const [contractsResponse, contractsRequest] = useApi<ListResult<Contract>>(
    carrierAdministration.getContracts,
    null
  );

  useEffect(() => {
    profileRequest();
    contractsRequest();
  }, [profileRequest, contractsRequest]);

  useEffect(() => {
    if (contractsResponse.errorMessage) {
      console.error(contractsResponse.errorMessage);
    }
    if (
      contractsResponse.isSuccess &&
      contractsResponse.data &&
      contractsResponse.data.count > 0
    ) {
      dispatch({
        type: SET_CONTRACT,
        contract: contractsResponse.data.results[0]
      });
    }
  }, [contractsResponse, dispatch]);

  useEffect(() => {
    if (profileResponse.isSuccess && profileResponse.data) {
      const profile = profileResponse.data.results.pop();
      if (profile) {
        dispatch({ type: SET_PROFILE, profile: profile });
        const location = profile.location;
        if (location) {
          dispatch({ type: SET_CURRENT_LOCATION, location: location });
        }
        const carrier = profile.carrier;
        if (carrier) {
          dispatch({ type: SET_CARRIER, carrier: carrier });
          const isTesting = carrier.is_testing;
          if (isTesting !== undefined) {
            dispatch({ type: SET_TESTING, isTesting: isTesting });
          }
        }
        if (profile.manager) {
          carrierRequest();
          dispatch({
            type: SET_BACKGROUND_COLOR,
            backgroundColor: theme.palette.secondary.light
          });
        } else {
          dispatch({
            type: SET_BACKGROUND_COLOR,
            backgroundColor: theme.palette.primary.light
          });
        }
      }
    }
  }, [profileResponse, theme.palette, dispatch, carrierRequest]);

  useEffect(() => {
    if (carrierResponse.isSuccess && carrierResponse.data) {
      const carrier = carrierResponse.data.results.pop();
      if (carrier) {
        dispatch({ type: SET_TESTING, isTesting: carrier.is_testing });
      }
    }
  }, [carrierResponse, dispatch]);

  return <></>;
};

export default Initializer;
