import { Box, Button, Typography } from '@mui/material';
import { useAuth } from 'react-oidc-context';

const InactivePage = () => {
  const auth = useAuth();

  return (
    <Box
      height="100vh"
      width="100vw"
      textAlign="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <div>
        <Typography gutterBottom>
          Du wurdest wegen Inaktivität abgemeldet.
        </Typography>
        <Button variant="contained" onClick={() => auth.signinRedirect()}>
          Anmelden
        </Button>
      </div>
    </Box>
  );
};

export default InactivePage;
