import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography
} from '@mui/material';
import carrierAdministration from 'api/carrierAdministration';
import useApi from 'hooks/useApi';
import { KeyedObject } from 'types';
import { Invitation } from 'types/diingu';

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

interface InvitationDialogProps extends KeyedObject {
  invitation: Invitation;
  open: boolean;
  handleClose: () => void;
}

const InvitationDialog = ({
  invitation,
  open,
  handleClose
}: InvitationDialogProps) => {
  const auth = useAuth();
  const [buttonDisabledState, setButtonDisabledState] = useState(false);

  const [acceptInvitationResponse, acceptInvitationRequest] = useApi<any>(
    carrierAdministration.acceptInvitation,
    null
  );
  const [declineInvitationResponse, declineInvitationRequest] = useApi<any>(
    carrierAdministration.declineInvitation,
    null
  );

  const handleAccept = () => {
    setButtonDisabledState(true);
    acceptInvitationRequest(invitation.id);
  };

  const handleDecline = () => {
    setButtonDisabledState(true);
    declineInvitationRequest(invitation.id);
  };

  useEffect(() => {
    if (
      declineInvitationResponse.isSuccess &&
      !declineInvitationResponse.isFetching
    ) {
      handleClose();
    }
    if (
      acceptInvitationResponse.isSuccess &&
      !acceptInvitationResponse.isFetching
    ) {
      window.location.reload();
    }
  }, [auth, declineInvitationResponse, acceptInvitationResponse, handleClose]);

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose();
    }
  };

  const role = invitation.role === 'assistant' ? 'Schulbegleitung' : 'Leitung';
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Du wurdest eingeladen!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography gutterBottom color="black">
            Der Träger {invitation.carrier} hat dich als {role} eingeladen.
          </Typography>
          <Link href="https://diingu.de/diingu-terms-user.pdf" target="_blank">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="subtitle2">
                Bei Annahme gelten unsere <b>AGB</b>
              </Typography>
              <OpenInNewRoundedIcon
                style={{ marginLeft: '0.5rem', fontSize: '1.1rem' }}
              />
            </div>
          </Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleDecline()}
          variant="outlined"
          color="error"
          sx={{ textTransform: 'none' }}
          disabled={buttonDisabledState}
        >
          Einladung ablehnen
        </Button>
        <Button
          onClick={() => handleAccept()}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none' }}
          disabled={buttonDisabledState}
        >
          Einladung annehmen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvitationDialog;
