import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

// routing
import Routes from 'routes';

// store
import { DefaultRootStateProps } from 'types';

// defaultTheme
import themes from 'themes';

// project imports
import Snackbar from 'components/extended/Snackbar';
import Locales from 'components/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import Initializer from 'components/Initializer';
import InactivePage from 'views/pages/InactivePage/InactivePage';
import LoadingPage from 'views/pages/LoadingPage/LoadingPage';
import FullScreenLoader from 'components/modals/FullScreenLoader';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector(
    (state: DefaultRootStateProps) => state.customization
  );
  const account = useSelector((state: DefaultRootStateProps) => state.account);
  const auth = useAuth();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }
  }, [
    auth,
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect
  ]);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <LoadingPage title="Du wirst angemeldet" />;
    case 'signoutRedirect':
      return <LoadingPage title="Du wirst abgemeldet" />;
  }

  if (auth.isLoading) {
    return <LoadingPage />;
  }

  if (auth.error) {
    console.error(auth.error.message);
    if (
      ['Session not active', 'Token is not active'].includes(auth.error.message)
    ) {
      return <InactivePage />;
    }
    auth.signoutRedirect();
    return <div>Bei der Anmeldung ist ein Fehler aufgetreten...</div>;
  }

  if (!auth.isAuthenticated) {
    return <div>Unable to log in</div>;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Locales>
          <NavigationScroll>
            <>
              <Initializer />
              <FullScreenLoader
                open={!account.profile}
                logoFile="./logo_vertikal.svg"
              />
              <Routes />
              <Snackbar />
            </>
          </NavigationScroll>
        </Locales>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
