declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  NODE_ENV: string;
  PUBLIC_URL: string;
  REACT_APP_API_URL: string;
  REACT_APP_BASE_URL: string;
  REACT_APP_OIDC_AUTH_URL: string;
  REACT_APP_OIDC_CLIENT_ID: string;
  REACT_APP_OIDC_ACCOUNT_URL: string;
  REACT_APP_OIDC_REDIRECT_URI: string;
};

export const env: EnvType = { ...process.env, ...window.env };
