import { createRoot } from 'react-dom/client';

// // third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';

// load mock apis
import '_mockApis';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
// import { persister } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from 'config';

import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

import fixRemoveCild from 'utils/browserfix';

import { env } from 'env';

// ==============================|| REACT DOM RENDER  ||============================== //

if (
  !env.REACT_APP_OIDC_AUTH_URL ||
  !env.REACT_APP_OIDC_REDIRECT_URI ||
  !env.REACT_APP_OIDC_CLIENT_ID
) {
  throw new Error('Oidc Environment variables not set correctly');
}

const oidcConfig: AuthProviderProps = {
  onSigninCallback: (user) => {
    window.history.replaceState({}, document.title, window.location.pathname);
    window.location.href = `/dashboard`;
  },
  authority: env.REACT_APP_OIDC_AUTH_URL!,
  redirect_uri: env.REACT_APP_OIDC_REDIRECT_URI!,
  client_id: env.REACT_APP_OIDC_CLIENT_ID!,
  post_logout_redirect_uri: 'https://diingu.de/',
  loadUserInfo: true,
  monitorSession: true,
  automaticSilentRenew: true
};

const container = document.getElementById('root');
const root = createRoot(container!);
fixRemoveCild();

root.render(
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persister}> */}
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
