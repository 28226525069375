import { useState, useCallback } from 'react';

type ReturnType<DataType> = [
  {
    data: DataType | null;
    isFetching: boolean;
    isSuccess: boolean;
    errorMessage: any;
  },
  (...args: any[]) => {}
];

// TODO: Function to reset the return (e.g. for reusable dialogs)
// TODO: Response type for post calls
function useApi<T>(
  apiFunction: Function,
  initialDataValue: T | null
): ReturnType<T> {
  const [response, setResponse] = useState({
    data: initialDataValue,
    errorMessage: null,
    isFetching: false,
    isSuccess: false
  });

  const request = useCallback(
    async (...args: any[]) => {
      setResponse((prevState) => ({
        ...prevState,
        isFetching: true
      }));
      try {
        const apiData = await apiFunction(...args);
        setResponse({
          data: apiData.data,
          isFetching: false,
          errorMessage: null,
          isSuccess: true
        });
      } catch (error: any) {
        setResponse({
          data: null,
          isFetching: false,
          errorMessage: error.message,
          isSuccess: false
        });
      }
    },
    [apiFunction]
  );

  return [response, request];
}

export default useApi;
