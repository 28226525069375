import { styled } from '@mui/material/styles';

const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '& .MuiListItem-root': {
    padding: 0
  }
}));

export default ListItemWrapper;
