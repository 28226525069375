/**
 * axios setup to use backend api
 */

import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

import { env } from 'env';

// Authentication

import getUser from './oidc';

/**
 * common interceptors
 */

const OidcRequestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const user = getUser();
  const accessToken = user?.access_token;
  if (accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    console.warn('Axios could not find access token');
  }

  return config;
};

const RequestErrorInterceptor = (error: AxiosError): Promise<AxiosError> =>
  Promise.reject(error);

const ResponseInterceptor = (response: AxiosResponse): AxiosResponse =>
  response;

const ResponseErrorInterceptor = async (error: any): Promise<any> =>
  Promise.reject(error);

/**
 * Axios Instance to communicate with the diingu backend
 */

const backendClient = axios.create({
  // TODO: ENV var for baseURL
  baseURL: env.REACT_APP_API_URL
    ? env.REACT_APP_API_URL
    : 'http://localhost:8000',
  timeout: 45000,
  headers: {
    Accept: 'application/json, image/*',
    'Content-Type': 'application/json'
  }
});

backendClient.interceptors.request.use(
  OidcRequestInterceptor,
  RequestErrorInterceptor
);
backendClient.interceptors.response.use(
  ResponseInterceptor,
  ResponseErrorInterceptor
);

/**
 * Axios instance to fetch media files
 */

const mediaClient = axios.create({
  baseURL: env.REACT_APP_BASE_URL
    ? env.REACT_APP_BASE_URL
    : 'http://localhost:8000',
  timeout: 10000,
  headers: {
    'Content-Type': 'image'
  }
});

mediaClient.interceptors.request.use(
  OidcRequestInterceptor,
  RequestErrorInterceptor
);

mediaClient.interceptors.response.use(
  ResponseInterceptor,
  ResponseErrorInterceptor
);

export { mediaClient };

export default backendClient;
