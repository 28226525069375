import { SET_CURRENT_LOCATION, SET_ALL_LOCATIONS } from './actions';
import { LocationStateProps } from 'types';
import { CarrierLocation } from 'types/diingu';

export interface LocationActionProps {
  type: string;
  location: CarrierLocation;
  locations: CarrierLocation[];
}

const initialState: LocationStateProps = {
  current: {
    id: -1,
    name: 'Alle Standorte'
  },
  all: []
};

// eslint-disable-next-line
const locationReducer = (state = initialState, action: LocationActionProps) => {
  switch (action.type) {
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        current: action.location
      };
    case SET_ALL_LOCATIONS:
      return {
        ...state,
        all: action.locations
      };
    default:
      return state;
  }
};

export default locationReducer;
