import { Navigate } from 'react-router-dom';

// project imports
// import MinimalLayout from 'layout/MinimalLayout';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <Navigate replace to="/dashboard" />,
  children: []
};

export default AuthenticationRoutes;
