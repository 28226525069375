import { useEffect, useRef, useState } from 'react';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import carrierAdministration from 'api/carrierAdministration';
import Transitions from 'components/extended/Transitions';
import useApi from 'hooks/useApi';
import { SET_ALL_LOCATIONS, SET_CURRENT_LOCATION } from 'store/actions';
import { DefaultRootStateProps } from 'types';
import { CarrierLocation, ListResult } from 'types/diingu';

const LocationSection = () => {
  // Hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const anchorRef = useRef<any>(null);
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  const [locationsResponse, locationsRequest] = useApi<
    ListResult<CarrierLocation>
  >(carrierAdministration.getLocations, null);

  // Global state
  const locationState = useSelector(
    (state: DefaultRootStateProps) => state.location.current
  );

  // Local state
  const [open, setOpen] = useState(false);
  const [currentLocation, setCurrentLocation] =
    useState<CarrierLocation>(locationState);
  const [locations, setLocations] = useState<CarrierLocation[]>([]);

  // Effects
  useEffect(() => {
    setCurrentLocation(locationState);
  }, [locationState]);

  useEffect(() => {
    locationsRequest();
  }, [locationsRequest]);

  useEffect(() => {
    if (locationsResponse.isSuccess && locationsResponse.data) {
      setLocations(locationsResponse.data.results);
      dispatch({
        type: SET_ALL_LOCATIONS,
        locations: locationsResponse.data.results
      });
    }
  }, [locationsResponse.data, locationsResponse.isSuccess, dispatch]);

  // Logic
  const handleListItemClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined,
    selectedLocation: CarrierLocation
  ) => {
    setCurrentLocation(selectedLocation);
    dispatch({ type: SET_CURRENT_LOCATION, location: selectedLocation });
    setOpen(false);
  };

  const handleToggle = () => {
    locationsRequest();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          [theme.breakpoints.down('md')]: {
            ml: 1
          }
        }}
      >
        <Button
          onClick={handleToggle}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          color="inherit"
          sx={{
            // borderRadius: '12px',
            border: '1px solid',
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.primary.light,
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.primary.light,
            color: theme.palette.primary.dark,
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              borderColor: theme.palette.primary.main,
              background: theme.palette.primary.main,
              color: theme.palette.primary.light
            },
            minWidth: 125,
            maxWidth: matchesXs ? 140 : 300
          }}
          endIcon={<ArrowDropDownRoundedIcon fontSize="large" />}
        >
          <Typography
            variant="h5"
            color="inherit"
            sx={{
              whiteSpace: 'nowrap',
              width: '80%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {currentLocation.name}
          </Typography>
        </Button>
      </Box>
      <Popper
        placement={matchesSm ? 'bottom-start' : 'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesSm ? 0 : 0, 20]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesSm ? 'top-left' : 'top'}
            in={open}
            {...TransitionProps}
          >
            <Paper
              elevation={16}
              style={{ maxHeight: '70vh', overflow: 'auto' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    width: '100%',
                    minWidth: 200,
                    maxWidth: 280,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 3,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250
                    }
                  }}
                >
                  <ListItemButton
                    selected={currentLocation.id === -1}
                    onClick={(event) => {
                      const alloc: CarrierLocation = {
                        id: -1,
                        name: 'Alle Standorte'
                      };
                      handleListItemClick(event, alloc);
                    }}
                    key="-1"
                  >
                    <ListItemText
                      primary={
                        <Grid container>
                          <Typography color="textPrimary" variant="h5">
                            Alle Standorte
                          </Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                  <Divider />
                  {locations.map((location) => (
                    <ListItemButton
                      selected={currentLocation.id === location.id}
                      onClick={(event) => handleListItemClick(event, location)}
                      key={location.id}
                    >
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">
                              {location.name}
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default LocationSection;
