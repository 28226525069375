import { Modal, Box, CircularProgress, Fade } from '@mui/material';
import { styled } from '@mui/system';

const FullScreenModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiBackdrop-root': {
    backgroundColor: 'white'
  },
  backgroundColor: theme.palette.background.default,
  transition: 'opacity 1000ms ease-in-out'
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none'
}));

const Logo = styled('img')({
  width: '150px',
  marginBottom: '40px'
});

interface FullScreenLoaderProps {
  open: boolean;
  logoFile: string;
}

const logoDir = require.context('assets/images/', true);

const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({
  open,
  logoFile
}) => {
  return (
    <FullScreenModal
      open={open}
      aria-labelledby="loading-modal"
      aria-describedby="loading-app-initialization"
      closeAfterTransition
    >
      <Fade in={open} exit={true} timeout={{ enter: 250, exit: 1500 }}>
        <ContentBox>
          <Logo src={logoDir(logoFile)} alt="Logo" />
          <CircularProgress />
        </ContentBox>
      </Fade>
    </FullScreenModal>
  );
};

export default FullScreenLoader;
