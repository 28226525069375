import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';

const Minimal = Loadable(lazy(() => import('layout/MinimalLayout')));

const Dashboard = Loadable(
  lazy(() => import('views/DashboardPage/DashboardPage'))
);

const ProfilePage = Loadable(
  lazy(() => import('views/user/ProfilePage/ProfilePage'))
);

// guiding
const Guiding = Loadable(lazy(() => import('views/guiding/GuidingPage')));
const CoursePage = Loadable(lazy(() => import('views/guiding/CoursePage')));
const PageItemPage = Loadable(
  lazy(() => import('views/guiding/contentPages/pageItemPage/PageItemPage'))
);
const QuizItemPage = Loadable(
  lazy(() => import('views/guiding/contentPages/quizItemPage/QuizItemPage'))
);
const QuizTakingPage = Loadable(
  lazy(
    () =>
      import(
        'views/guiding/contentPages/quizItemPage/quizTakingPage/QuizTakingPage'
      )
  )
);
const QuizResultsPage = Loadable(
  lazy(
    () =>
      import(
        'views/guiding/contentPages/quizItemPage/quizResultPage/QuizResultsPage'
      )
  )
);

const CertificatesPage = Loadable(
  lazy(() => import('views/assistants/CertificatePage/CertificatesPage'))
);

const CertificatePage = Loadable(
  lazy(() => import('views/assistants/CertificatePage/CertificatePage'))
);

// management
const AssistantsPage = Loadable(
  lazy(() => import('views/management/AssistantsPage/AssistantsPage'))
);
const ManagersPage = Loadable(
  lazy(() => import('views/management/ManagersPage/ManagersPage'))
);
const LocationsPage = Loadable(
  lazy(() => import('views/admin/LocationsPage/LocationsPage'))
);
const CourseOverviewPage = Loadable(
  lazy(() => import('views/management/CourseOverviewPage/CourseOverviewPage'))
);
const ManagementCoursePage = Loadable(
  lazy(() => import('views/management/CourseOverviewPage/ManagementCoursePage'))
);
const ManagementModuleItemPage = Loadable(
  lazy(() => import('views/management/CourseOverviewPage/ModuleItemPage'))
);

// Administration
const CarrierAdminPage = Loadable(
  lazy(() => import('views/admin/CarrierAdminPage'))
);

const UserProfilePage = Loadable(
  lazy(() => import('views/UserProfilePage/UserProfilePage'))
);

const LicensePage = Loadable(
  lazy(() => import('views/admin/LicensePage/ContractPage'))
);

const ReportsPage = Loadable(
  lazy(() => import('views/management/ReportsPage/ReportsPage'))
);

const TeamsListPage = Loadable(
  lazy(() => import('views/management/TeamsPage/TeamsListPage'))
);

const TeamsPage = Loadable(
  lazy(() => import('views/management/TeamsPage/TeamsPage'))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/minimal',
      element: <Minimal />
    },
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/profile',
      element: <ProfilePage />
    },
    {
      path: '/user/:id',
      element: <UserProfilePage />
    },
    // Guiding routes
    {
      path: '/guiding',
      element: <Guiding />
    },
    {
      path: '/guiding/courses/:courseId',
      element: <CoursePage />
    },
    {
      path: '/guiding/courses/:courseId/modules/:moduleId/pages/:pageUrlId',
      element: <PageItemPage />
    },
    {
      path: '/guiding/courses/:courseId/modules/:moduleId/quizzes/:quizId',
      element: <QuizItemPage />
    },
    {
      path: '/guiding/courses/:courseId/modules/:moduleId/quizzes/:quizId/take',
      element: <QuizTakingPage />
    },
    {
      path: '/guiding/courses/:courseId/modules/:moduleId/quizzes/:quizId/results',
      element: <QuizResultsPage />
    },
    {
      path: '/certificates',
      element: <CertificatesPage />
    },
    {
      path: '/certificates/:certificateId',
      element: <CertificatePage />
    },
    // Management routes
    {
      path: '/locations',
      element: <LocationsPage />
    },
    {
      path: '/assistants',
      element: <AssistantsPage />
    },
    {
      path: '/managers',
      element: <ManagersPage />
    },
    {
      path: '/reports',
      element: <ReportsPage />
    },
    {
      path: '/teams',
      element: <TeamsListPage />
    },
    {
      path: '/teams/:teamId',
      element: <TeamsPage />
    },
    {
      path: '/course-overview',
      element: <CourseOverviewPage />
    },
    {
      path: '/course-overview/courses/:courseId',
      element: <ManagementCoursePage />
    },
    {
      path: '/course-overview/courses/:courseId/modules/:moduleId/items/:itemId',
      element: <ManagementModuleItemPage />
    },
    // Admin routes
    {
      path: '/admin',
      element: <CarrierAdminPage />
    },
    {
      path: '/licenses',
      element: <LicensePage />
    }
  ]
};

export default MainRoutes;
