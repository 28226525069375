// TODO: Split up the api in multiple modules
import backendClient from 'utils/axios';

import {
  ProfileUpdate,
  CarrierCreate,
  InvitationCreate,
  ActivityStatusChoices,
  UserLicenseState
} from 'types/diingu';

const createCarrier = async (carrier: CarrierCreate) => {
  const date = new Date();
  const data = {
    legal_consent: date.toISOString(),
    ...carrier
  };
  return backendClient.post('/carriers/', data);
};

const updateCarrierData = async (id: number, carrier: CarrierCreate) =>
  backendClient.patch(`/carriers/${id}/`, { ...carrier });

const getCarrierData = async () => backendClient.get('/carriers/');

const inviteUser = async (invite: InvitationCreate) =>
  backendClient.post('/invitations/', { ...invite });

const getAssistant = async (id: number) =>
  backendClient.get(`/assistants/${id}/`);

const updateAssistant = async (id: number, team: string) =>
  backendClient.patch(`/assistants/${id}/`, { team });

const getAssistantProfiles = async (location?: number) =>
  backendClient.get('/profiles/', { params: { is_assistant: true, location } });

const getManagerProfiles = async (location?: number) =>
  backendClient.get('/profiles/', { params: { is_manager: true, location } });

const getAnalyticReports = async (location?: number) => {
  if (location === undefined)
    return backendClient.get('/analytic-reports/', {
      params: { has_location: false }
    });
  return backendClient.get('/analytic-reports/', { params: { location } });
};

const getDailyReports = async (afterDate: string, location?: number) => {
  if (location === undefined)
    return backendClient.get('/daily-reports/', {
      params: { has_location: false, created_after: afterDate }
    });
  return backendClient.get('/daily-reports/', {
    params: { location, created_after: afterDate }
  });
};

const getWeeklyReports = async (afterDate: string, location?: number) => {
  if (location === undefined)
    return backendClient.get('/weekly-reports/', {
      params: { has_location: false, created_after: afterDate }
    });
  return backendClient.get('/weekly-reports/', {
    params: { location, created_after: afterDate }
  });
};

const getAssistants = async (
  location?: number,
  activityStatus?: ActivityStatusChoices,
  team?: string
) => {
  if (location === undefined) {
    return backendClient.get('/assistants/', {
      params: { has_location: false, activity_status: activityStatus, team }
    });
  }
  return backendClient.get('/assistants/', {
    params: { location, activity_status: activityStatus, team }
  });
};

const getProfile = async (id?: number) =>
  backendClient.get(`/profiles/${id || ''}`);

const getOwnProfile = async () =>
  backendClient.get('/profiles/', { params: { is_own: true } });

const getProfileAsManager = async (id: number) =>
  backendClient.get(`/profiles/${id}/?role=manager`);

const getOwnInvitations = async () =>
  backendClient.get('/invitations/', {
    params: { is_own: true, state: 'pending' }
  });

const acceptInvitation = async (id: number) =>
  backendClient.put(`/invitations/${id}/`);

const declineInvitation = async (id: number) =>
  backendClient.delete(`/invitations/${id}/`);

// Management

const removeAssistant = async (id: number) =>
  backendClient.delete(`/assistants/${id}/`);

const removeManager = async (id: number) =>
  backendClient.delete(`/managers/${id}/`);

// Other stuff

const getImpairments = async () => backendClient.get('/impairments/');

const archiveStudent = async (id: number) =>
  backendClient.delete(`/students/${id}/`);

const revokeInvitation = async (id: number) =>
  backendClient.delete(`/invitations/${id}/`);

const resendInvitation = async (id: number) =>
  backendClient.put(`/invitations/${id}/`);

const getAssistantInvitations = async (state?: string) =>
  backendClient.get('/invitations/', { params: { role: 'assistant', state } });

const getManagerInvitations = async () =>
  backendClient.get('/invitations/', { params: { role: 'manager' } });

const updateProfile = async (profile: ProfileUpdate) =>
  backendClient.patch(`/profiles/${profile.id}/`, {
    location: profile.locationId,
    employee_number: profile.employee_number
  });

const getLocations = async () => backendClient.get('/locations/');

const createLocation = async (name: string) =>
  backendClient.post('/locations/', { name });

const removeLocation = async (id: number) =>
  backendClient.delete(`/locations/${id}/`);

const changeLocation = async (id: number, name: string) =>
  backendClient.patch(`/locations/${id}/`, { name });

const getLicenses = async () => backendClient.get('/licenses/');

const getUserLicenses = async (
  profile?: number,
  is_open?: boolean,
  state?: UserLicenseState
) =>
  backendClient.get('/user-licenses/', { params: { profile, is_open, state } });

const updateUserLicense = async (id: string, is_renewing: boolean) => {
  return backendClient.patch(`/user-licenses/${id}/`, {
    is_renewing
  });
};

const terminateLicense = async (id: number) =>
  backendClient.delete(`/licenses/${id}/`);

const revokeLicenseTermination = async (id: number) =>
  backendClient.put(`/licenses/${id}/`);

const addGuidingProgress = async (course_id: number, quiz_id: number) =>
  backendClient.post('/guiding_progresses/', { course_id, quiz_id });

const getGuidingProgress = async () =>
  backendClient.get('/guiding_progresses/');

const getTeams = async (location: number) =>
  backendClient.get('/teams/', { params: { location } });

const getTeam = async (teamId: string) =>
  backendClient.get(`/teams/${teamId}/`);

const createTeam = async (
  name: string,
  manager: number,
  locationId: number
) => {
  const location = locationId === -1 ? undefined : locationId;
  return backendClient.post('/teams/', { name, manager, location });
};

const removeTeam = async (teamId: string) =>
  backendClient.delete(`/teams/${teamId}/`);

const changeTeam = async (
  teamId: string,
  name?: string,
  manager?: number,
  location?: number
) => backendClient.patch(`/teams/${teamId}/`, { name, location, manager });

const getContracts = async () => backendClient.get('/contracts/');

const carrierAdministration = {
  createCarrier,
  getCarrierData,
  updateCarrierData,
  inviteUser,
  getAssistant,
  updateAssistant,
  getAssistantProfiles,
  getManagerProfiles,
  getOwnInvitations,
  acceptInvitation,
  declineInvitation,
  getOwnProfile,
  getProfile,
  getProfileAsManager,
  getImpairments,
  removeAssistant,
  removeManager,
  archiveStudent,
  revokeInvitation,
  resendInvitation,
  getAssistantInvitations,
  getManagerInvitations,
  updateProfile,
  getLocations,
  createLocation,
  removeLocation,
  changeLocation,
  getLicenses,
  terminateLicense,
  revokeLicenseTermination,
  addGuidingProgress,
  getAnalyticReports,
  getGuidingProgress,
  getDailyReports,
  getWeeklyReports,
  getAssistants,
  getTeams,
  getTeam,
  createTeam,
  removeTeam,
  changeTeam,
  getContracts,
  getUserLicenses,
  updateUserLicense
};

export default carrierAdministration;
