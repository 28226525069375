// assets
import {
  IconHome,
  IconUsers,
  IconBuilding,
  IconRocket,
  IconCoffee,
  IconMapPin,
  IconLicense,
  IconFileDescription,
  IconCertificate,
  IconBooks
  // IconUsersGroup
} from '@tabler/icons';

import { IconUsersGroup } from '@tabler/icons-react';

import { NavItemGroup } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const generalItems: NavItemGroup = {
  id: 'general-menu-items',
  type: 'individual',
  role: '',
  children: [
    {
      id: 'overview',
      title: 'Übersicht',
      type: 'item',
      url: '/dashboard',
      icon: IconHome
    }
  ]
};

const initialAdminItems: NavItemGroup = {
  id: 'initial-admin-menu-items',
  title: 'administration',
  type: 'group',
  role: 'carrier_admin',
  children: [
    {
      id: 'carrier-admin',
      title: 'Trägerdaten',
      type: 'item',
      url: '/admin',
      icon: IconBuilding,
      breadcrumbs: true
    }
  ]
};

const adminItems: NavItemGroup = {
  id: 'admin-menu-items',
  title: 'administration',
  type: 'group',
  role: 'carrier_admin',
  children: [
    {
      id: 'carrier-admin',
      title: 'Träger',
      type: 'item',
      url: '/admin',
      icon: IconBuilding,
      breadcrumbs: true
    },
    {
      id: 'locations',
      title: 'Standorte',
      type: 'item',
      url: '/locations',
      icon: IconMapPin,
      breadcrumbs: false
    },
    {
      id: 'licenses',
      title: 'Vertragsübersicht',
      type: 'item',
      url: '/licenses',
      icon: IconLicense,
      breadcrumbs: false
    }
  ]
};

const managementItems: NavItemGroup = {
  id: 'management-menu-items',
  title: 'Verwaltung',
  type: 'group',
  role: 'manager',
  children: [
    {
      id: 'teams',
      title: 'Teams',
      type: 'item',
      url: '/teams',
      icon: IconUsersGroup,
      breadcrumbs: false
    },
    {
      id: 'managers',
      title: 'Leitungspersonal',
      type: 'item',
      url: '/managers',
      icon: IconCoffee,
      breadcrumbs: false
    },
    {
      id: 'assistants',
      title: 'Schulbegleitungen',
      type: 'item',
      url: '/assistants',
      icon: IconUsers,
      breadcrumbs: false
    },
    {
      id: 'reports',
      title: 'Reporting',
      type: 'item',
      url: '/reports',
      icon: IconFileDescription,
      breadcrumbs: false
    },
    {
      id: 'course-overview',
      title: 'Kursübersicht',
      type: 'item',
      url: '/course-overview',
      icon: IconBooks,
      breadcrumbs: false
    }
  ]
};

const assistantItems: NavItemGroup = {
  id: 'assistant-menu-items',
  title: 'Fortbildung',
  type: 'group',
  role: 'assistant',
  children: [
    {
      id: 'guiding',
      title: 'Kurse',
      type: 'item',
      url: '/guiding',
      icon: IconRocket,
      breadcrumbs: false
    },
    {
      id: 'certificates',
      title: 'Zertifikate',
      type: 'item',
      url: '/certificates',
      icon: IconCertificate,
      breadcrumbs: false
    }
  ]
};

// const freemiumItems: NavItemGroup = {
//   id: 'freemium-menu-items',
//   type: 'group',
//   role: 'freemium',
//   children: [
//     {
//       id: 'certificates',
//       title: 'Zertifikate',
//       type: 'item',
//       url: '/certificates',
//       icon: IconCertificate,
//       breadcrumbs: false
//     }
//   ]
// };

export {
  generalItems,
  adminItems,
  managementItems,
  assistantItems,
  // freemiumItems,
  initialAdminItems
};
