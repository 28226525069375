import { CircularProgress, Grid } from '@mui/material';

const CircularLoading = () => (
  <Grid
    container
    alignContent="center"
    width="100%"
    height="100%"
    direction="column"
  >
    <Grid
      item
      container
      alignContent="center"
      justifyContent="center"
      width="100%"
      height="100%"
      direction="row"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  </Grid>
);

export default CircularLoading;
