import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
  Theme
} from '@mui/material';

import SelfImprovementRoundedIcon from '@mui/icons-material/SelfImprovementRounded';

import { KeyedObject } from 'types';

import ListItemWrapper from './ListItemWrapper';

export interface NoNotificationProps extends KeyedObject {
  theme: Theme;
}

const NoNotification = ({ theme }: NoNotificationProps) => (
  <ListItemWrapper key="no-notification">
    <ListItem alignItems="center">
      <ListItemAvatar>
        <Avatar
          alt="Invite"
          sx={{
            color: theme.palette.primary.dark,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.primary.light,
            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
            borderColor: theme.palette.primary.main
          }}
        >
          <SelfImprovementRoundedIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Keine Benachrichtigungen" />
    </ListItem>
    <Grid container direction="column" className="list-container">
      <Grid item xs={12} sx={{ pb: 2 }}>
        <Typography variant="subtitle2">
          Welch eine Ruhe, momentan hast du keine Benachrichtigungen.
        </Typography>
      </Grid>
    </Grid>
  </ListItemWrapper>
);

export default NoNotification;
