// material-ui
import { useTheme } from '@mui/material/styles';
import { List } from '@mui/material';

// assets
import { KeyedObject } from 'types';
import { Invitation } from 'types/diingu';
import InvitationNotification from './InvitationNotification';
import NoNotification from './NoNotification';

export interface NotificationListProps extends KeyedObject {
  invites?: Invitation[];
  handleToggle: () => void;
}

const NotificationList = ({ invites, handleToggle }: NotificationListProps) => {
  const theme = useTheme();

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {invites?.map((invite, index) => (
        <InvitationNotification
          key={`invite-${index}`}
          invite={invite}
          theme={theme}
          handleToggle={handleToggle}
        />
      ))}
      {invites?.length === 0 ? (
        <NoNotification key="no-notification" theme={theme} />
      ) : (
        <></>
      )}
    </List>
  );
};

export default NotificationList;
