import { DefaultRootStateProps } from 'types';
import { Carrier, Contract, Profile } from 'types/diingu';
import { SET_TESTING, SET_CARRIER, SET_PROFILE, SET_CONTRACT } from './actions';

export interface AccountActionProps {
  type: string;
  isTesting: boolean;
  carrier: Carrier;
  profile: Profile;
  contract: Contract;
}

const initialState: DefaultRootStateProps['account'] = {
  isActivated: true,
  isLoggedIn: true,
  isTesting: true,
  carrier: undefined,
  profile: undefined,
  contract: undefined
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action: AccountActionProps) => {
  switch (action.type) {
    case SET_TESTING:
      return {
        ...state,
        isTesting:
          action.isTesting === undefined
            ? initialState.isTesting
            : action.isTesting
      };
    case SET_CARRIER:
      return {
        ...state,
        carrier: action.carrier ? action.carrier : initialState.carrier
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case SET_CONTRACT:
      return {
        ...state,
        contract: action.contract
      };
    default:
      return state;
  }
};

export default accountReducer;
