import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
import NotificationList from './NotificationList';
import useApi from 'hooks/useApi';
import { ListResult, Invitation } from 'types/diingu';
import carrierAdministration from 'api/carrierAdministration';

// assets
import { IconBell } from '@tabler/icons';
import InvitationDialog from 'components/dialogs/InvitationDialog/InvitationDialog';

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inviteAmount, setInvitationAmount] = useState(0);
  const [invites, setInvitations] = useState<Invitation[]>([]);
  /**
   * anchorRef is used on different components and specifying one type leads to
   * other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  // Periodically check for invites every minute
  useEffect(() => {
    const interval = setInterval(() => {
      inviteRequest();
    }, 5000);

    return () => clearInterval(interval);
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    inviteRequest();
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setDialogOpen(invites.length > 0);
  }, [invites]);

  // Load invites
  const [inviteResponse, inviteRequest] = useApi<ListResult<Invitation>>(
    carrierAdministration.getOwnInvitations,
    null
  );
  useEffect(() => {
    inviteRequest();
  }, [inviteRequest]);
  useEffect(() => {
    if (inviteResponse.isSuccess && inviteResponse.data) {
      setInvitationAmount(inviteResponse.data.count);
      setInvitations(inviteResponse.data.results);
    }
  }, [inviteResponse.data, inviteResponse.isSuccess]);

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Badge badgeContent={inviteAmount} color="error">
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.dark.main
                    : theme.palette.secondary.light,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.warning.dark
                    : theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.warning.dark
                      : theme.palette.secondary.dark,
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.grey[800]
                      : theme.palette.secondary.light
                }
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="inherit"
            >
              <IconBell stroke={1.5} size="1.3em" />
            </Avatar>
          </Badge>
        </ButtonBase>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? 'top' : 'top-right'}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{
                          height: '100%',
                          maxHeight: 'calc(100vh - 205px)',
                          overflowX: 'hidden'
                        }}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <NotificationList
                          invites={invites}
                          handleToggle={handleToggle}
                        />
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {invites.map((invitation) => (
        <InvitationDialog
          invitation={invitation}
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
        />
      ))}
    </>
  );
};

export default NotificationSection;
