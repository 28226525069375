// material-ui
import { Typography } from '@mui/material';

// project imports
import {
  generalItems,
  adminItems,
  managementItems,
  assistantItems
} from 'menu-items/items';
import { NavItemGroup } from 'types';
import NavGroup from './NavGroup';
import useRoles from 'hooks/useRoles';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const roles = useRoles();

  const menuItems: { items: NavItemGroup[] } = {
    items: [generalItems, adminItems, managementItems, assistantItems]
  };

  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'individual':
        return <NavGroup key={item.id} item={item} />;
      case 'group':
        if (roles.includes(item.role))
          return <NavGroup key={item.id} item={item} />;
        break;
      default:
        return (
          <Typography key={item.id} variant="h5" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
    return null;
  });

  return <>{navItems}</>;
};

export default MenuList;
