import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';

const useRoles = () => {
  const profile = useSelector(
    (state: DefaultRootStateProps) => state.account.profile
  );

  const baseRoles = ['freemium'];

  if (!profile) return baseRoles;

  if (profile.manager) {
    baseRoles.push('manager');
  }

  if (profile.assistant) {
    baseRoles.push('assistant');
  }

  if (profile.carrier_admin) {
    baseRoles.push('carrier_admin');
  }

  return baseRoles;
};

export default useRoles;
