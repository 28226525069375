import { useState, useEffect } from 'react';

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  Typography,
  Theme,
  Button,
  Link
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';

import { KeyedObject } from 'types';
import { Invitation } from 'types/diingu';

import ListItemWrapper from './ListItemWrapper';
import useApi from 'hooks/useApi';
import carrierAdministration from 'api/carrierAdministration';
import { useAuth } from 'react-oidc-context';

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

export interface InvitationNotificationProps extends KeyedObject {
  invite: Invitation;
  theme: Theme;
  handleToggle: () => void;
}

const InvitationNotification = ({
  invite,
  theme,
  handleToggle
}: InvitationNotificationProps) => {
  const auth = useAuth();

  const role = invite.role === 'assistant' ? 'Schulbegleitung' : 'Leitung';
  const [toggled, setToggled] = useState(false);

  const [acceptInvitationResponse, acceptInvitationRequest] = useApi<any>(
    carrierAdministration.acceptInvitation,
    null
  );
  const [declineInvitationResponse, declineInvitationRequest] = useApi<any>(
    carrierAdministration.declineInvitation,
    null
  );

  if (acceptInvitationResponse.errorMessage)
    console.error(acceptInvitationResponse.errorMessage);

  if (declineInvitationResponse.errorMessage)
    console.error(declineInvitationResponse.errorMessage);

  useEffect(() => {
    if (
      declineInvitationResponse.isSuccess &&
      !declineInvitationResponse.isFetching
    ) {
      if (!toggled) {
        setToggled(true);
        handleToggle();
      }
    }
    if (
      acceptInvitationResponse.isSuccess &&
      !acceptInvitationResponse.isFetching
    ) {
      auth.signinSilent();
      if (!toggled) {
        setToggled(true);
        handleToggle();
      }
    }
  }, [
    auth,
    declineInvitationResponse,
    acceptInvitationResponse,
    handleToggle,
    toggled
  ]);

  return (
    <ListItemWrapper key={`invite-${invite.id}`}>
      <ListItem alignItems="center" key={`invite-${invite.id}`}>
        <ListItemAvatar>
          <Avatar
            alt="Invitation"
            sx={{
              color: theme.palette.primary.dark,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.primary.light,
              border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
              borderColor: theme.palette.primary.main
            }}
          >
            <EmailIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Einladung" />
      </ListItem>
      <Grid container direction="column" className="list-container">
        <Grid item xs={12} sx={{ pb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Du wurdest eingeladen dem Träger {invite.carrier} als {role}{' '}
            beizutreten.
          </Typography>
          <Link href="https://diingu.de/diingu-terms-user.pdf" target="_blank">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="subtitle2">
                Bei Annahme gelten unsere <b>AGB</b>
              </Typography>
              <OpenInNewRoundedIcon
                style={{ marginLeft: '0.5rem', fontSize: '1.1rem' }}
              />
            </div>
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        className="list-container"
        justifyItems="center"
        spacing={1}
      >
        <Grid item xs={6}>
          <Button
            variant="outlined"
            sx={{
              color: theme.palette.error.main,
              borderColor: theme.palette.error.main,
              '&:hover': {
                borderColor: theme.palette.error.main,
                backgroundColor: theme.palette.error.main,
                color: theme.palette.background.default
              }
            }}
            onClick={() => {
              declineInvitationRequest(invite.id);
            }}
          >
            Ablehnen
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={() => {
              acceptInvitationRequest(invite.id);
            }}
          >
            Annehmen
          </Button>
        </Grid>
      </Grid>
    </ListItemWrapper>
  );
};

export default InvitationNotification;
