import { DefaultRootStateProps } from 'types';
import QuizSubmissionQuestion from 'types/learning';
import { ADD_QUIZ, UPDATE_ANSWER } from './actions';

export interface LearningActionProps {
  type: string;
  answer: QuizSubmissionQuestion;
  quizId: string;
}

const initialState: DefaultRootStateProps['learning'] = {
  quizzes: []
};

// eslint-disable-next-line
const learningReducer = (state = initialState, action: LearningActionProps) => {
  switch (action.type) {
    case ADD_QUIZ: {
      const qIndex = state.quizzes.findIndex(
        (quiz) => quiz.id === action.quizId
      );
      if (-1 < qIndex) {
        return state;
      }
      const newState = state;
      newState.quizzes = [...state.quizzes, { id: action.quizId, answers: [] }];
      return newState;
    }

    case UPDATE_ANSWER: {
      const qIndex = state.quizzes.findIndex(
        (quiz) => quiz.id === action.quizId
      );
      if (-1 === qIndex) {
        return state;
      }

      const index = state.quizzes[qIndex].answers.findIndex(
        (ans) => ans.id === action.answer.id
      );
      if (-1 < index) {
        const newState = state;
        const updateAnswer = newState.quizzes[qIndex].answers[index];
        updateAnswer.answer = action.answer.answer
          ? action.answer.answer
          : updateAnswer.answer;
        updateAnswer.isCorrect = action.answer.isCorrect
          ? action.answer.isCorrect
          : updateAnswer.isCorrect;
        newState.quizzes[qIndex].answers[index] = updateAnswer;
        return newState;
      }

      const newState = state;
      newState.quizzes[qIndex].answers = [
        ...newState.quizzes[qIndex].answers,
        action.answer
      ];
      return newState;
    }

    default:
      return state;
  }
};

export default learningReducer;
