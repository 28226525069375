import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import locationReducer from './locationReducer';
import snackbarReducer from './snackbarReducer';
import accountReducer from './accountReducer';
import quizReducer from './learningReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  location: locationReducer,
  account: accountReducer,
  learning: quizReducer
});

export default reducer;
