import { User } from 'oidc-client-ts';
import { env } from 'env';

const getUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${env.REACT_APP_OIDC_AUTH_URL}:${env.REACT_APP_OIDC_CLIENT_ID}`
  );
  if (!oidcStorage) {
    console.warn('could not find user in storage');
    return null;
  }
  return User.fromStorageString(oidcStorage);
};

export default getUser;
